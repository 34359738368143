import React from 'react';

import Slide1 from 'src/slides/desktop/hu/chapter_3/sub_5/slide1';

import WrapperMobile from 'src/slides/mobile/hu/chapter_3/sub_5/wrapperMobile';

import { isMobile } from 'react-device-detect';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="A lengyel-bolsevik háború kalendáriuma | A varsói csata" description="A Wilnóért vívott harcoktól Bugyonnij ellentámadásáig – a lengyel-bolsevik háború története" lang="hu" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile


const Mobile = () => (
  <>
    <SEO title="A lengyel-bolsevik háború kalendáriuma | A varsói csata" description="A Wilnóért vívott harcoktól Bugyonnij ellentámadásáig – a lengyel-bolsevik háború története" lang="hu" />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
